import React from "react"
import Body from '../src/components/Body'
import Header from '../src/components/Header'
import './App.css';


function App() {
  return (
    <div className="glitch">
      <Header />
      <Body />
    </div>
  )
}

export default App;
