import React from "react"
import '../App.css'

const Body = () => {
  return (
    <div className="background">
      <svg
        className="bottom"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        viewBox="0 24 150 28"
        preserveAspectRatio="none"
        shapeRendering="auto"
      >
        <defs>
          <path
            id="gentle-wave"
            d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z"
          />
        </defs>
        <g className="parallax">
          <use xlinkHref="#gentle-wave" x="48" y="0" fill="#d32c46" />
          <use xlinkHref="#gentle-wave" x="48" y="5" fill="#2fd036" />
          <use xlinkHref="#gentle-wave" x="40" y="7" fill="#a5ae51" />
          <use xlinkHref="#gentle-wave" x="40" y="7" fill="#e8ec13" />
          <use xlinkHref="#gentle-wave" x="48" y="3" fill="#2493db" />
        </g>
      </svg>
    </div>
  )
}

export default Body
