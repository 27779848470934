import React from "react"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import { fab } from '@fortawesome/free-brands-svg-icons'
import { fas } from '@fortawesome/free-solid-svg-icons'
import image from '../components/images/image.png'
import '../App.css'

library.add(fab, fas)

const Header = () => {
return (
  <div className="img-header ">
    <img src={image} alt={"good vibes growing logo"} />
    <a href="https://instagram.com/goodvibesgrowing?igshid=YmMyMTA2M2Y=">
      <FontAwesomeIcon
        id="instagram"
        className="ig-icon"
        icon={["fab", "instagram"]}
      />
    </a>
  </div>
) 
}
export default Header